<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";


export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      marketing_campaign_areas: [],
      marketing_campaign_channels: [],

      MarketingCampaigns: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMarketingCampaigns: null,
      MarketingCampaignsSearchMode: false,
      searchModel: "",

    };
  },
  methods: {
    addMarketingCampaigns() {

      var data = {
        title: "popups.add",
        inputs: [
          { model: "name", type: "text", label: "name" },
          { model: "descr", type: "text", label: "descr" },
          { model: "start_time", type: "date", label: "start_time" },
          { model: "finish_time", type: "date", label: "finish_time" },

        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log({ obj });
              this.http.post("marketing-campaigns", obj, null, true).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editMarketingCampaigns(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          { model: "name", type: "text", label: "name", value: app.name },
          { model: "descr", type: "text", label: "descr", value: app.descr },
          {
            model: "start_time",
            type: "date",
            label: "start_time",
            value: app.start_time,
          },
          {
            model: "finish_time",
            type: "date",
            label: "finish_time",
            value: app.finish_time,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log({ obj });
              this.http.put("marketing-campaigns", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateMarketingCampaigns(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "name", type: "text", label: "name" },
          { model: "descr", type: "text", label: "descr" },
          { model: "start_time", type: "text", label: "start_time" },
          { model: "finish_time", type: "text", label: "finish_time" },
          {
            model: "marketing_campaign_areas",
            type: "select",
            options: (() => {
              return this.marketing_campaign_areas.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.marketing_campaign_areas",
          },

          {
            model: "marketing_campaign_channels",
            type: "select",
            options: (() => {
              return this.marketing_campaign_channels.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.marketing_campaign_channels",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("marketing-campaigns", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.MarketingCampaignsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-campaigns/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        }, null, true)
        .then((res) => {
          this.MarketingCampaigns = res.data;
          console.log("######## files", this.MarketingCampaigns);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.MarketingCampaignsSearchMode = false;
      this.get(this.page);
    },

    getMarketingCampaigns() {
      this.http.get("marketing-campaigns", null, null, true).then((res) => {
        this.MarketingCampaigns = res.data;
      });
    },
    deleteMarketingCampaigns(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("marketing-campaigns", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("marketing-campaigns/pagination", {
          limit: this.limit,
          page: page,
        }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.MarketingCampaigns = res.data;

        });
      // });
    },
  },
  created() {
    this.get(1);
    // this.getMarketingCampaigns();
  },
};
</script>

<template>
  <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.marketing.subItems.marketing_campaign_management')
    " />
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button class="btn-close text-light" v-if="MarketingCampaignsSearchMode" @click="cancelappsearchMode()"></button>
      <button type="button" @click="addMarketingCampaigns()" class="btn btn-light float-end mb-4">
        <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">{{ $t("marketing.id") }}</th>
        <th scope="col">{{ $t("marketing.name") }}</th>
        <th scope="col">{{ $t("marketing.descr") }}</th>
        <th scope="col">
          {{ $t("marketing.campaigns_management.start_time") }}
        </th>
        <th scope="col">
          {{ $t("marketing.campaigns_management.finish_time") }}
        </th>
        <th scope="col">{{ $t("marketing.created") }}</th>
        <th scope="col">{{ $t("marketing.updated") }}</th>
        <th scope="col">{{ $t("marketing.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(app, i) in MarketingCampaigns" :key="app" class="text-center">
        <td>{{ i + 1 }}</td>
        <td>{{ app?.name }}</td>
        <td>{{ app?.descr }}</td>
        <td>{{ app?.start_time?.split("T")[0] }}</td>
        <td>{{ app?.finish_time?.split("T")[0] }}</td>
        <td>{{ app?.created?.split("T")[0] }}</td>
        <td>{{ app?.updated?.split("T")[0] }}</td>

        <td class="d-flex justify-content-end">
          <router-link :to="'/dashboard/marketing_campaign_management/' + app?.id">
            <button class="btn btn-info mx-1" role="button" data-toggle="tooltip" data-placement="top"
              :title="app.name + $t('popups.details')">
              <i class="bx bxs-detail"></i>
            </button>
          </router-link>
          <a class="btn btn-primary mx-1" @click="editMarketingCampaigns(app)" href="javascript: void(0);"
            role="button">{{ $t("popups.edit") }}</a>
          <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteMarketingCampaigns(app)">{{
            $t("popups.delete") }}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages == 20">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
